<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right" >
      <el-breadcrumb-item>广告主管理</el-breadcrumb-item>
      <el-breadcrumb-item>广告主投放申请</el-breadcrumb-item>
    </el-breadcrumb>
    <br />
    <!-- <el-row>
        <el-col :span="3" style="margin-right: 30px">
            <el-input v-model="temp.title" placeholder="广告标题"></el-input>
        </el-col>
        <el-col :span="3" style="margin-right: 30px">
            <el-button type="primary" @click="search">查询</el-button>
        </el-col>
    </el-row> -->


    <!--表单 BEGIN -->
    <el-dialog title="状态审核" :visible.sync="dialogFormVisible">
      <el-form ref="dataForm" :rules="rules" :model="listQuery" label-position="left" label-width="100px" style="width: 600px; margin-left:50px;">
        <el-form-item label="ID" prop="id" label-width="100px">
            <el-input v-model="listQuery.id"  disabled></el-input>
        </el-form-item>
        <el-form-item label="标题" prop="title" label-width="100px">
            <el-input v-model="listQuery.title"  disabled></el-input>
        </el-form-item>
         <el-form-item label="内容" prop="content" label-width="100px">
            <el-input v-model="listQuery.content"  disabled></el-input>
        </el-form-item>
        <el-form-item label="账号类型" prop="biddingType" label-width="100px">
            <el-input v-model="listQuery.biddingType"  disabled></el-input>
        </el-form-item>
        <el-form-item label="竞价价格" prop="bidding" label-width="100px" v-if="listQuery.biddingType == '竞价广告'">
            <el-input v-model="listQuery.bidding"  disabled></el-input>
        </el-form-item>
         <el-form-item label="合约价格" prop="contractPrice" label-width="100px" v-if="listQuery.biddingType == '自助合约广告'">
            <el-input v-model="listQuery.contractPrice"></el-input>
        </el-form-item>
        <el-form-item label="审核类型" prop="auditStatus" label-width="100px">
            <el-radio-group v-model="listQuery.auditStatus">
                <el-radio :label="2">审核通过</el-radio>
                <el-radio :label="3">审核拒绝</el-radio>
            </el-radio-group>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">
          取消
        </el-button>
        <el-button type="primary" @click="updateData">
          保存
        </el-button>
      </div>
    </el-dialog>

    <!--表单 END -->
    <div style="background: #fff ">
    <el-table
        v-loading="loadings"
        ref="singleTable"
        :data="listData"
        border
        fit
        style="width: 100%;height: 100%"
    >
      <el-table-column
          fixed
          fit
          align="center"
          type="index"
          min-width="20">
      </el-table-column>

      <el-table-column prop="title" label="标题" min-width="40" />
      <el-table-column prop="content" label="内容" min-width="40" />
      <el-table-column prop="biddingType" label="账号类型" min-width="40">
        <template  slot-scope="scope">
          {{ scope.row.biddingType == 1 ? '竞价广告' : '自助合约广告'}}
        </template>
      </el-table-column>
      <el-table-column prop="region" label="投放地区" min-width="40" />
      <el-table-column prop="bidding" label="竞价价格" min-width="40">
        <template  slot-scope="scope">
          {{ scope.row.bidding ? scope.row.bidding : '-'}}
        </template>
      </el-table-column>
      <el-table-column prop="contractPrice" label="合约价格" min-width="40">
        <template  slot-scope="scope">
          {{ scope.row.contractPrice ? scope.row.contractPrice : '-'}}
        </template>
      </el-table-column>
      <el-table-column prop="tagList" label="投放标签" min-width="40" />
      <el-table-column label="审核状态" align="center" min-width="50">
        <template slot-scope="scope">
            <el-button type="primary" @click="handleDalog(scope.row, scope.$index)" v-if="scope.row.auditStatus == 1">
                审核
            </el-button>
            <span v-if="scope.row.auditStatus !== 1">{{scope.row.auditStatus == 2 ? '已通过' : '已拒绝'}}</span>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination style="background: #fff ;margin-top: 20px;margin-left: 10px"
                   background @current-change="handleCurrentChange" :page-size="listQuery.size" layout="total,prev, pager, next" :total="total">
    </el-pagination>
    <div style="height: 30px;" />

    </div>


  </div>
</template>

<script>

import { fetchAuditList,updataAudit } from "@/api/adUsers";

export default {
  name: "appProfit",
  components: {  },
  inject: ['load','closeLoad'],
  data() {
    const typeNameRules = (rule,value,callback) =>{
        console.log(value)
        if(value == 1) {
            callback(new Error('不能为空'))
        } else {
            callback()
        }
    }
    const price = (rule,value,callback) =>{
        if(value !== '') {
          let digit
            if(value.toString().indexOf('.') > -1) {
              digit = value.toString().split('.')[1].length
            }
            if (value <= 0) {
                callback(new Error('请输入正确的标签价格'))
            } else if(digit > 2) {
              callback(new Error('最多保留两位小数'))
            }
            else {
                callback()
            }
        } else {
            callback(new Error('请输入价格'))
        }
    }
    return {
      total: 0,
      listQuery: {
        current: 1,
        size: 10,
        dateYMd:'',
        appId:'',
        id:'',
        power:'',
        name:'',
        tagId:'',
        biddingType:'',
        bidding:'',
        auditStatus:'',
        content:'',
        title:'',
        contractPrice:''
      },
      switchValue1: false,
      dialogFormVisible: false,
      dialogFormVisible1:false,
      dialogStatus: '',
      dialogStatus1:'',
      textMap: {
        update: '编辑',
        create: '添加'
      },
      rules: {
        auditStatus: [{ required: true, validator: typeNameRules, trigger: 'blur' }],
        contractPrice: [{ required: true, validator: price, trigger: 'change' }]
      },
      temp: {
        id: undefined,
        appId: '',
        adId: '',
        dateYMd: '',
        actualRevenue: '',
        actualRatio: '',
        showRevenue: '',
        ecpm: '',
        askNumber: '',
        exposureNumber: '',
        clickNumber: '',
        clickRate: '',
        adName: '',
        title:''
      },
      fomrs:{
        appId: '',
        dateYMd: '',
        adId:''
      },
      loadings:false,
      appName:'',
      tableData: [],
      appOptions:[],
      adOptions:[],
      companyOptions:[],
      qadOptions:[],
      listData:[],
      checkedList:[],
    }
  },
  created() {
    // this.getList();
    this.getAdvertiseList()
  },
  methods:{
    getAdvertiseList() {
      let para = {
        current: this.listQuery.current,
        size: this.listQuery.size
      }
      fetchAuditList(para).then(response => {
          if(response.code === 1) {
              this.closeLoad()
              console.log(response)
              let datas = response.body.records
              datas.forEach((item)=>{
                item.cp = item.status !== 1 ? false : true
                item.region = item.province + '/' + item.city + (item.district ? '/' + item.district : '')
                if(item.tagNames.length > 0) {
                    let str = ''
                    item.tagNames.forEach((data)=>{
                        str += data + '/'
                    })
                    item.tagList = str.substring(0, str.length - 1)
                } else {
                    item.tagList = '-'
                }
              })
              this.listData = datas
              this.total = response.body.total
          }
      })
    },
    handleDalog(data,i) {
        this.dialogFormVisible = true
        this.listQuery.id = data.id
        this.listQuery.biddingType = data.biddingType == 1 ? '竞价广告' : '自助合约广告'
        this.listQuery.bidding = data.bidding
        this.listQuery.auditStatus = data.auditStatus
        this.listQuery.content = data.content
        this.listQuery.title = data.title
        this.listQuery.contractPrice = data.contractPrice
    },
    updateData() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
            let typeFomr = {
                id:this.listQuery.id,
                auditStatus:this.listQuery.auditStatus,
                contractPrice:this.listQuery.contractPrice
            }
          updataAudit(typeFomr).then((res) => {
            if(res.code === 1) {
                this.dialogFormVisible = false
                this.$notify({
                title: 'Success',
                message: '审批完成',
                type: 'success',
                duration: 2000
                })
                this.getAdvertiseList()
            }
          })
        }
      })
    },
    // search() {
    //   if(!this.listQuery.name) {
    //     this.$message({
    //       showClose: true,
    //       message: '请选择app应用',
    //       type: 'warning'
    //     });
    //     return
    //   }
    //   this.load()
    //   this.listQuery.current = 1
    //   this.getAdvertiseList()
    // },
    handleCurrentChange(val) {
      this.listQuery.current = val;
      this.getAdvertiseList()
    },
    // handleDelete(index, row) {
    //   console.log(index, row);
    //   this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     type: 'warning'
    //   }).then(() => {
    //     AdvertiseDel({id: row.id}).then(() => {
    //       this.$message({
    //         type: 'success',
    //         message: '删除成功!'
    //       });
    //       this.getAdvertiseList()
    //       // this.tableData.splice(index, 1)
    //     })

    //   }).catch(() => {});
    // }

  }
}

</script>

<style scoped>


</style>
